import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
  Textarea,
} from '@chakra-ui/react';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

function ContactForm({ formName = 'contact' }) {
  const [formSubmitted, setFormSubmitted] = useState(false);
  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  }

  const ContactFormSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required.'),
    lastName: Yup.string().required('Last name is required.'),
    email: Yup.string().email('Invalid email').required('Email is required.'),
    phone: Yup.number()
      .min(
        10,
        'Must be a 10-digit australian number. Please include area code.'
      )
      .integer('Must be a whole number.')
      .positive('Must be a positive number'),
    company: Yup.string().notRequired(),
    message: Yup.string().required('Message is required.'),
    discover: Yup.string().required('Required.'),
  });

  if (formSubmitted) {
    return (
      <Box w="100%">
        <Heading size="lg" mt={8}>
          Thank you!
        </Heading>
        <Text>Somone will be in touch with you shortly.</Text>
      </Box>
    );
  } else {
    return (
      <Box w="100%">
        <Formik
          initialValues={{
            fistName: '',
            lastName: '',
            email: '',
            phone: '',
            company: '',
            discover: '',
          }}
          validationSchema={ContactFormSchema}
          onSubmit={(values) => {
            const data = encode({
              'form-name': `${formName}`,
              ...values,
            });
            axios
              .post('/', data, {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              })
              .then(() => {
                setFormSubmitted(true);
              })
              .catch((err) => console.error(err.message));
          }}
        >
          {({ handleSubmit, isSubmitting, dirty, isValid }) => (
            <Form
              name={formName}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />
              <Flex
                justifyContent="center"
                alignItems="center"
                mt={8}
                flexDir={{ base: 'column', md: 'row' }}
              >
                <Field name="firstName">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.firstName && form.touched.firstName
                      }
                      mr={{ base: 0, md: 5 }}
                      position="relative"
                    >
                      <FormLabel htmlFor="firstName">First name</FormLabel>
                      <Input
                        {...field}
                        id="firstName"
                        placeholder="First name"
                        bgColor="white"
                      />
                      <FormErrorMessage>
                        {form.errors.firstName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="lastName">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.lastName && form.touched.lastName}
                      position="relative"
                      mt={{ base: 4, md: 0 }}
                    >
                      <FormLabel htmlFor="lastName">Last name</FormLabel>
                      <Input
                        {...field}
                        id="lastName"
                        placeholder="Last name"
                        bgColor="white"
                      />
                      <FormErrorMessage>
                        {form.errors.lastName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>
              <Field name="email">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.email && form.touched.email}
                    mt={4}
                    position="relative"
                  >
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input
                      {...field}
                      id="email"
                      placeholder="Email"
                      bgColor="white"
                    />
                    <FormErrorMessage position="absolute" top="4.4rem" left={0}>
                      {form.errors.email}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="phone">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.phone && form.touched.phone}
                    mt={4}
                    position="relative"
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      <FormLabel htmlFor="phone">Phone</FormLabel>
                      <Text fontSize=".8rem" color="gray.500">
                        Optional
                      </Text>
                    </Flex>
                    <Input
                      {...field}
                      type="number"
                      id="phone"
                      placeholder="Phone"
                      bgColor="white"
                    />
                    <FormErrorMessage position="absolute" top="4.4rem" left={0}>
                      {form.errors.phone}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="company">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.company && form.touched.company}
                    mt={4}
                    position="relative"
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      <FormLabel htmlFor="company">Company</FormLabel>
                      <Text fontSize=".8rem" color="gray.500">
                        Optional
                      </Text>
                    </Flex>
                    <Input
                      {...field}
                      id="company"
                      placeholder="Company"
                      bgColor="white"
                    />
                    <FormErrorMessage>{form.errors.company}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="message">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.message && form.touched.message}
                    mt={4}
                    position="relative"
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      <FormLabel htmlFor="message">
                        How can we help you?
                      </FormLabel>
                    </Flex>
                    <Textarea
                      {...field}
                      id="message"
                      placeholder="Write your message here"
                      bgColor="white"
                    />
                    <FormErrorMessage>{form.errors.message}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="discover">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.discover && form.touched.discover}
                    mt={4}
                    position="relative"
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      <FormLabel htmlFor="discover">
                        How did you hear about us?
                      </FormLabel>
                    </Flex>
                    <Select
                      {...field}
                      id="discover"
                      placeholder="Select"
                      bgColor="white"
                    >
                      <option>Word of mouth</option>
                      <option>Google/internet</option>
                      <option>Ad</option>
                      <option>Through my organisation</option>
                      <option>From the media</option>
                    </Select>
                    <FormErrorMessage position="absolute" top="4.4rem" left={0}>
                      {form.errors.discover}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Divider mt={4} />
              <Flex justifyContent="flex-end" alignItems="center" mt={4}>
                <Button
                  colorScheme="secondary"
                  isLoading={isSubmitting}
                  isDisabled={!(dirty && isValid)}
                  type="submit"
                  w={{ base: '100%', md: 'auto' }}
                >
                  Submit
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
    );
  }
}

export default ContactForm;
